<template>
    <div id="insect-handle" class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px"
            style="margin: 14px 36px 4px; float: right">
            <el-form-item label="" prop="deviceName">
                <el-input v-model="queryParams.deviceName" placeholder="请输入设备名称" clearable
                    @keyup.enter.native="handleQuery" />
            </el-form-item>
            <el-form-item>
                <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
                <el-button icon="el-icon-refresh" @click="resetQuery">重置</el-button>
            </el-form-item>
        </el-form>

        <el-card style="width: 94%; height: 70vh; margin-left: 3%">
            <el-table v-loading="loading" :data="deviceList">
                <!-- <el-table-column label="id" align="center" prop="id" /> -->
                <el-table-column label="设备名称" align="center" prop="deviceCode">
                </el-table-column>
                <el-table-column label="设备描述" align="center" width="300" prop="deviceDescription" />
                <el-table-column label="归属信息" align="center" prop="landPk">
                    <template slot-scope="scope">
                        <el-popover title="土地信息" placement="right" width="200" trigger="hover">
                            <p style="font-size:16px;margin:6px 0;"><b>土地面积：</b>{{scope.row.area}}亩</p>
                            <p style="font-size:16px;margin:6px 0;"><b>土地类型：</b>{{scope.row.landType}}</p>
                            <p style="font-size:16px;margin:6px 0;"><b>所属区域：</b>{{scope.row.region}}</p>
                            <p style="font-size:16px;margin:6px 0;"><b>测绘情况：</b>{{scope.row.isMapping}}</p>
                            <span slot="reference">{{scope.row.name}}&nbsp;<i class="el-icon-info"></i></span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" icon="el-icon-setting" @click="toManure(scope.row)">详情
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize" @pagination="getList" style="width: 94%; margin: 8px auto" />
    </div>
</template>

<script>
    export default {
        name: "Device",
        components: {},
        data() {
            return {
                // 遮罩层
                loading: true,
                // 导出遮罩层
                exportLoading: false,
                // 选中数组
                ids: [],
                // 非单个禁用
                single: true,
                // 非多个禁用
                multiple: true,
                // 显示搜索条件
                showSearch: true,
                // 总条数
                total: 0,
                // 【请填写功能名称】表格数据
                deviceList: [],
                // 弹出层标题
                title: "",
                // 是否显示弹出层
                open: false,
                // 查询参数
                queryParams: {
                    pageNum: 1,
                    pageSize: 10,
                    deviceName: null,
                    productKey: null,
                    nSumFertilizerAmount: null,
                    nFertilizationCount: null,
                    pSumFertilizerAmount: null,
                    pFertilizationCount: null,
                    kSumFertilizerAmount: null,
                    kFertilizationCount: null,
                    flush: null,
                    flushCount: null,
                },
                // 表单参数
                form: {},
                // 表单校验
                rules: {},
                save: [],
            };
        },
        created() {
            this.getList();
        },
        methods: {
            toManure(e) {
                sessionStorage.setItem("deviceCode", e.deviceCode);
                this.$router.replace("insectDetail");
            },
            getList() {
                this.loading = true;
                this.$webAxios.post("/tdmap/getInsertDevices")
                    .then((response) => {
                        response = response.data.data;
                        this.deviceList = response;
                        this.total = response.length;
                        this.loading = false;
                        this.$axios.get("/api/Land/GetLandsByCompany", {}, {
                            headers:
                            {
                                'Content-Type': 'application/x-www-form-urlencoded',
                                'Authorization': 'Bearer ' + localStorage.token
                            },
                        })
                            .then(res => {
                                let land = res.data.data;
                                for (let i = 0; i < this.deviceList.length; i++) {
                                    land.filter(item => {
                                        if (this.deviceList[i].landPk == item.id) {
                                            this.deviceList[i].name = item.name;
                                            this.deviceList[i].area = item.area;
                                            this.deviceList[i].region = item.regionName;
                                            this.deviceList[i].landType = item.typeName;
                                            this.deviceList[i].isMapping = item.isMappingCompletedName;
                                            this.$set(this.deviceList, i, this.deviceList[i]);
                                        }
                                    })
                                }
                                this.save = this.deviceList;
                            })
                    });
            },
            handleQuery() {
                this.queryParams.pageNum = 1;
                this.queryParams.productKey = '';
                this.deviceList = this.save;
                this.deviceList = this.deviceList.filter(item => {
                    return item.deviceCode.includes(this.queryParams.deviceName);
                })
            },
            resetQuery() {
                this.queryParams.deviceName = '';
                this.deviceList = this.save;
            },
        },
    };
</script>